@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
}

.nav {
  width: 100%;
  background-color: #282c34;
  color: white;
  text-align: center;
  padding: 1rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 8px;
  margin: 20px auto;
  padding: 0;
}

.question-title {
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 20px;
  border-radius: 8px 8px 0 0;
  text-align: center;
  margin: 0;
  box-sizing: border-box;
}

.options-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(50% - 20px);
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.option:hover {
  background-color: #f0f0f0;
}

.option img {
  width: 100%;
  max-width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
  display: block;
}

.option label {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
}

.footer {
  width: 100%;
  background-color: #282c34;
  color: white;
  text-align: center;
  padding: 1rem;
  margin-top: auto; /* Empurra o footer para o final da página */
}

.button-container {
  display: flex;
  justify-content: flex-end; /* Alinha o botão Próximo à direita */
  gap: 20px; /* Espaçamento entre os botões */
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
}

.spacer {
  flex: 1;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #61dafb;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #21a1f1;
}


.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ocupa a altura total da tela */
  padding: 20px;
  background-color: #f4f4f4; /* Cor de fundo para contraste */
}

.result-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 800px;
  width: 100%;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.result-image {
  max-width: 100%;
  border-radius: 8px;
  margin-top: 20px;
}

.restart-button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #61dafb;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.restart-button:hover {
  background-color: #21a1f1;
}

.intro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ocupa a altura total da tela */
  padding: 20px;
  background-color: #f4f4f4;
}

.intro-content {
  text-align: center;
  max-width: 800px;
  width: 100%;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.start-button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #61dafb;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.start-button:hover {
  background-color: #21a1f1;
}

/* Estilos anteriores mantidos */

/* Estilo para o componente ProgressBar */
.progress-bar-container {
  width: 100%;
  background-color: #e0e0df;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  height: 20px;
}

.progress-bar {
  height: 100%;
  background-color: #61dafb;
  transition: width 0.3s ease-in-out;
}

.progress-text {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 0;
  left: 0;
  font-size: 14px;
  color: #333;
  line-height: 20px; /* Alinha o texto verticalmente */
}
